import React from 'react';

import { useGetHomePageVideoHeroesQuery } from '@alpha-shares/graphql-codegen/generated/graphCMS';

import { graphCMSClient } from '@alpha-shares/fetchers';

const VideoHero: React.FC = () => {

  const { data } = useGetHomePageVideoHeroesQuery(graphCMSClient)

  const videoHero = data?.homePageVideoHeroes?.[0]

  const url = videoHero?.video?.url as string;
  const posterUrl = videoHero?.videoPosterImage?.url as string;

  const [videoUrl, setVideoUrl] = React.useState<string | null>(null)
  const [poster, setPoster] = React.useState<string>('')

  React.useEffect(() => {
    if (url) {
      setVideoUrl(url)
    }
    if (posterUrl) {
      setPoster(posterUrl)
    }
  }, [url, posterUrl])

  if (!videoUrl) {
    return null
  }

  return (
    // @ts-ignore
      <div className="inset-0 -mt-[80px] lg:h-screen h-[380px] lg:max-h-[1080px] -z-10" >
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          poster={poster}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default VideoHero;