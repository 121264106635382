import { NextPage, GetStaticProps } from 'next'
import { QueryClient, dehydrate } from '@tanstack/react-query'

import { paths } from '@reservoir0x/reservoir-sdk'
import supportedChains, { DefaultChain } from '@alpha-shares/res-utils/chains'
import { Head } from '@alpha-shares/react-components/common/Head'

// import { BLOCKCHAIN_CONFIG } from '@alpha-shares/config'

// import { ChainId } from '@alpha-shares/web3-sdk'

import { basicFetcher as fetcher } from 'utils/fetcher'

import {
  withLayout,
  StandardLayout,
  // TrendingCollectionsCarousel,
  // LatestListingsCarousel,
  HeroCarouselSquare,
  MintsGrid,
  Button
} from '@alpha-shares/react-components'

import { TrendingCollectionsTable } from '@alpha-shares/react-components/collections/TrendingCollectionsTable'

// import { TopSellingCollections, TrendingCollections } from '@alpha-shares/react-components/Features'

import { FeaturedCreatorsCarousel } from '@alpha-shares/react-components/Features'

import {
  FeaturedCollectionGroupDocument,
  type FeaturedCollectionGroupQueryVariables,
  GetAllMintingProjectsDocument,
  GetAllMintingProjectsQuery,
  GetHomePageVideoHeroesDocument
} from '@alpha-shares/graphql-codegen/generated/graphCMS'
import { ssrGetGraphCMSData, graphCMSClient } from '@alpha-shares/fetchers'

import VideoHero from '@alpha-shares/react-components/Organisms/VideoHero'

const Home: NextPage<any> = () => {

  return (
    <>
      <Head />
      
      <VideoHero />
     
      <div className='md:container mx-auto px-6 flex flex-col gap-12 -mt-[160px] lg:-mt-[254px] z-100'>
        
        <Button className="relative hidden lg:block w-[350px] mx-auto" size="large" href="https://x.com/alphashares" target="_blank">set your reminder</Button>
        <Button className="relative lg:hidden w-[250px] mx-auto " size="small" href="https://x.com/alphashares" target="_blank">set your reminder</Button>
        <HeroCarouselSquare />
        
        <TrendingCollectionsTable />

        <MintsGrid title='Featured Mints' />

        <FeaturedCreatorsCarousel />
        
        {/* <TopSellingCollections /> */}
        
        
      </div>

          
          {/* <LatestListingsCarousel title={'Latest Fear City by Stephen Bliss Listings'} queryParams = {{
            numberPerChain: 24,
            query: [
              {
                chainId: ChainId.ETHEREUM,
                contracts: [
                  '0x92ac03d1eb81bb8402fcdbe3ef43c6ca0cbba8f9', 
                ]
              }
            ]
            }} 
          />
      
          
          <TrendingCollectionsCarousel title='All Chains Trending' />
          
          <LatestListingsCarousel title={'Latest Tez Listings'} queryParams = {{
            numberPerChain: 24,
            query: [
              {
                chainId: ChainId.AVALANCHE,
                contracts: [
                  '0xc2ae13a358500ed76cddb368add0fb5de68318a7', 
                ]
              }
            ]
            }} 
          />
         
          <TrendingCollectionsCarousel title='Avalanche Trending Collections' numberPerChain={12} chainId={[43114]} />
          <LatestListingsCarousel title={'Latest Alpha Pack Listings'} queryParams = {{
            numberPerChain: 24,
            query: [
              {
                // nativeSource: 'www.alphashares.io',
                chainId: ChainId.AVALANCHE,
                contracts: [BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE]],
              },
              // {
              //   chainId: ChainId.ETHEREUM,
              //   contracts: [
              //     '0xa88b82af76ecf08cf652846d10857eaeeca40c97', 
              //     // '0x60e4d786628fea6478f785a6d7e704777c86a7c6'
              //   ]
              // }
            ]
            }} 
          />
          <TrendingCollectionsCarousel title='Ethereum Trending Collections' numberPerChain={6} chainId={[1]} />
          <LatestListingsCarousel title={'Latest MAYC Listings'} queryParams = {{
            numberPerChain: 24,
            query: [
              // {
              //   nativeSource: 'www.alphashares.io',
              //   chainId: ChainId.AVALANCHE,
              //   contracts: [BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE], '0xcf91b99548b1c17dd1095c0680e20de380635e20'],
              // },
              {
                chainId: ChainId.ETHEREUM,
                contracts: [
                  // '0xa88b82af76ecf08cf652846d10857eaeeca40c97', 
                  '0x60e4d786628fea6478f785a6d7e704777c86a7c6'
                ]
              }
            ]
            }} 
          />
          <TrendingCollectionsCarousel title='Polygon and Optimism Trending Collections' numberPerChain={6} chainId={[137, 10]} /> 
          <LatestListingsCarousel title={'Latest Trump Digital Trading Cards Listings'} queryParams = {{
            numberPerChain: 24,
            query: [
              // {
              //   nativeSource: 'www.alphashares.io',
              //   chainId: ChainId.AVALANCHE,
              //   contracts: [BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE], '0xcf91b99548b1c17dd1095c0680e20de380635e20'],
              // },
              {
                chainId: ChainId.POLYGON,
                contracts: [
                  '0x24a11e702cd90f034ea44faf1e180c0c654ac5d9', 
                  // '0x60e4d786628fea6478f785a6d7e704777c86a7c6'
                ]
              }
            ]
            }} 
          />           */}
          
        
        {/* <div className='mt-6 mx-6'>
          <TrendingCollections />
        </div> */}
    </>
  )
}

type TopSellingCollectionsSchema =
  paths['/collections/top-selling/v2']['get']['responses']['200']['schema']

type ChainTopSellingCollections = Record<string, TopSellingCollectionsSchema>

export const getStaticPaths = async () => {
 
  const paths = supportedChains.map((item) => {
    return {
      params: {
        chain: item.routePrefix
      }
    }
  })

  return {
    paths,
    fallback: 'blocking'
  }
}

// @ts-ignore
export const getStaticProps: GetStaticProps = async ({ params }) => {

  const queryClient = new QueryClient();

  const chain = supportedChains.find((chain) => chain.routePrefix === params?.chain) ||
    DefaultChain

  let baseUrl = process.env.NEXT_PUBLIC_HOST_URL
  let basePath = process.env.NEXT_PUBLIC_BASE_PATH

  if (baseUrl && (!baseUrl?.includes('https://') && !baseUrl?.includes('http://'))) {
    baseUrl = `https://${baseUrl}`;
  }

  const queryURL = `${baseUrl}${basePath}/api/reservoir/${chain.routePrefix}/collections/top-selling/v2?period=24h&includeRecentSales=true&limit=7&fillType=sale`

  try {   
    await queryClient.prefetchQuery(['topSellingCollections', chain?.id], () => fetcher(queryURL).then(res => res.data))   
  } catch (e) {
    console.error(e)
  }

  try {
    const variables: FeaturedCollectionGroupQueryVariables = {
      id: 'clrnu0zd7w0hp0bivbemr9pga'
    }

    const promises = [
      queryClient.prefetchQuery(["getHomePageVideoHeroes"], () => ssrGetGraphCMSData(GetHomePageVideoHeroesDocument)),
      queryClient.prefetchQuery(['getAllMintingProjects'], () => ssrGetGraphCMSData<GetAllMintingProjectsQuery>(GetAllMintingProjectsDocument)),
      queryClient.prefetchQuery(["featuredCollectionGroup", variables], () => ssrGetGraphCMSData(FeaturedCollectionGroupDocument)),      
    ]
  
    await Promise.all(promises)
   
  } catch (e) {
    console.error(e)
  }

  return {
    props: { 
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60     
  }
}

export default withLayout(StandardLayout, Home)
